<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <p>
        <b>
          При переезде в новый офис услуги от ОнлайнЛоджик можно взять с собой.
        </b>
      </p>
      <p>
        Сохраняйте свой номер договора, баланс, логин, пароль, тариф и все подключенные услуги.
        Оставьте заявку на перенос услуг, и мы свяжемся с Вами в ближайшее время!
      </p>
      <br />
      <b-form-group label="Укажите контактные данные для связи специалиста:">
        <BaseTextInput
          v-model="userData[0].value"
          :placeholder="userData[0].placeholder"
        />
        <br />
        <InputPhone
          v-model="userData[1].value"
          :placeholder="userData[1].placeholder"
        />
      </b-form-group>
      <b-form-group label="Укажите адрес текущего подключения">
        <BaseTextInput
          v-model="addressCurrent"
          placeholder="Адрес текущего подключения"
        />
      </b-form-group>
      <b-form-group label="Укажите новый адрес подключения">
        <BaseTextInput
          v-model="addressNew"
          placeholder="Новый адрес подключения"
        />
      </b-form-group>
      <b-form-group label="Планируемая дата переезда">
        <BaseInputDate v-model="dateRelocation.date"/>
      </b-form-group>
    </template>
  </ServicesDescription>
</template>

<script>
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      userData: [
        { label: 'ФИО', value: '', placeholder: 'ФИО ответственного лица' },
        { label: 'Телефон', value: '', placeholder: 'Номер телефона ответственного лица' }
      ],
      addressCurrent: '',
      addressNew: '',
      dateRelocation: {
        label: 'Планируемая дата переезда',
        date: ShowCurrentDate()
      }
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    InputPhone,
    ServicesDescription
  },

  computed: {
    currentData() {
      return {
        MoveService: {
          ContactName: this.userData[0].value,
          ContactPhone: this.userData[1].value,
          OldBCAddress: this.addressCurrent,
          NewBCAddress: this.addressNew,
          MoveDate: this.dateRelocation.date
        }
      };
    },

    isValid() {
      const {
        ContactName,
        ContactPhone,
        OldBCAddress,
        NewBCAddress
      } = this.currentData.MoveService;
      return Boolean(ContactName && ContactPhone.length === 18 && OldBCAddress && NewBCAddress);
    }
  },

  methods: {
    getDocument() {
      this.createDocument(this.currentData, this.isValid);
    }
  }
};
</script>

<style></style>
